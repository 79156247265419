<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/volunteers/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body v-if="!is.loading">

		<app-content-summary v-if="!isNew">

			<app-content-summary-item :grow="true" label="Status" :tag="references.status[item.status]" :status="item.status" />
			<app-content-summary-item :grow="true" label="Eligibility" :value="references.eligibility[item.eligibility]" />
			<app-content-summary-item :grow="true" label="Type" :value="references.type[item.type]" />
			<app-content-summary-item :grow="true" label="Slots" :value="item.count.slots" v-if="hasSlots" />
			<app-content-summary-item :grow="true" label="Volunteers" :value="item.count.filled" />
			<app-content-summary-item :grow="true" label="Capacity" :value="item.count.capacity" />
			
		</app-content-summary>

		<app-content-box :readonly="$authorised('con/volunteers/access', 'read')">

			<app-input-text label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Status" v-model="model.status" :validation="$v.model.status" :options="references.status" :descriptions="$constants.volunteers.role.statusDescription" />
			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Eligibility" v-model="model.eligibility" :validation="$v.model.eligibility" :options="references.eligibility" :descriptions="$constants.volunteers.role.eligibilityDescription" />
			<app-input-checklist :collapsed="!isNew" :locked="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Type" v-model="model.type" :validation="$v.model.type" :options="references.type" :descriptions="$constants.volunteers.role.typeDescription" />
			<app-input-text label="Max. Required" placeholder="Enter number..." v-model="model.slots.capacity" :validation="$v.model.slots.capacity" :numeric="true" v-if="!hasSlots" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/volunteers/access', 'read')">

			<app-input-text label="Description" :autogrow="true" placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" />
			<app-input-image label="Image" placeholder="Select image..." v-model="model.image" :validation="$v.model.image" />

		</app-content-box>

		<app-content-box icon="schedule" title="Slots" :readonly="$authorised('con/volunteers/access', 'read')" v-if="hasSlots && isNew">

			<app-input-toggle label="Auto-generate" v-model="model.slots.generate" :validation="$v.model.slots.generate" />
		
			<template v-if="model.slots.generate">

				<app-input-toggle label="Applicable Days" :asString="true" v-model="model.slots.days" :validation="$v.model.slots.days" :options="references.days" :multiple="true" :column="true" />
				<app-input-select label="Start Time" v-model="model.slots.start" :validation="$v.model.slots.start" :options="references.times" placeholder="Select time" />
				<app-input-text label="Slots per Day" placeholder="Enter number..." v-model="model.slots.daily" :validation="$v.model.slots.daily" :numeric="true" />
				<app-input-select label="Slot Duration" v-model="model.slots.duration" :validation="$v.model.slots.duration" :options="references.durations" placeholder="Select duration" />
				<app-input-text label="Volunteers per Slot" placeholder="Enter number..." v-model="model.slots.capacity" :validation="$v.model.slots.capacity" :numeric="true" />

			</template>
			
		</app-content-box>

		<app-content-section v-if="!isNew && hasSlots" route="Convention.Volunteers.Slots" :params="{ role: model.id }" title="Slots" description="Manage the slots for this role." :count="item.count.slots" />
		<app-content-section v-if="!isNew" route="Convention.Volunteers.Assignments" :params="{ role: model.id }" title="Volunteers" description="Manage the volunteers assigned to this role." :count="item.count.filled" />

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/volunteers/access', 'read'),
			references: {
				days: [],
				durations: [],
				times: [],
				eligibility: [],
				status: [],
				type: []
			},
			model: {
				id: false,
				name: '',
				description: '',
				image: '',
				status: this.$constants.volunteers.role.status.open,
				type: this.$constants.volunteers.role.type.schedule,
				eligibility: this.$constants.volunteers.role.eligibility.all,
				slots: {
					generate: 0,
					days: [],
					start: 0,
					daily: 0,
					duration: 0,
					capacity: 0
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			status: {
				required
			},
			type: {
				required
			},
			slots: {
				
			}
		}
	},

	computed: {

		hasSlots: function() {

			return this.model.type === this.$constants.volunteers.role.type.schedule

		},

		isManaged: function() {

			return this.model.eligibility === this.$constants.volunteers.role.eligibility.managed

		}

	}

}

</script>

<style scoped>

</style>